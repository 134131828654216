export default class Location {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.location = {
      city_id: null,
      cityName: '',
      translations: [
        { id: null, address: '', lang: 'ar' },
        { id: null, address: '', lang: 'en' }
      ],
      locations: []
    }
  }

  fillData (data) {
    if (data) {
      const itemAr = data.translations?.find(o => o.lang === 'ar')
      const itemEn = data.translations?.find(o => o.lang === 'en')

      this.location.city_id = data.city_id ? data.city_id : null
      this.location.cityName = data.cityName ? data.cityName : ''
      this.location.translations[0].id = itemAr ? itemAr.id : null
      this.location.translations[0].address = itemAr ? itemAr.address : ''
      this.location.translations[1].id = itemEn ? itemEn.id : null
      this.location.translations[1].address = itemEn ? itemEn.address : ''
      this.location.locations = data.locations ? data.locations : []
    } else {
      this.setInitialValue()
    }
  }
}
