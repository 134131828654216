<template>
  <div>
    <b-card>
      <ValidationObserver ref="location" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="location.location.translations[0].address" :name="$t('locations.addressAr')" :label="$t('locations.addressAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="location.location.translations[1].address" :name="$t('locations.addressEn')" :label="$t('locations.addressEn')" validate="required" :disabled="disableStatus" />
          </b-col>

          <b-col md="12">
            <main-select :options="cities" :label="'name'" v-model="location.location.city_id" :reduce="i => i.id" :text="location.location.cityName" :labelTitle="$t('cities.item')" validate="required" :showAsInfo="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import locationServices from '../services/locations'
import citiesServices from '../../cities/services/cities'
import Location from '../models/Location'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  data () {
    return {
      location: new Location(),
      cities: []
    }
  },
  methods: {
    async create () {
      locationServices.create(this.location).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'generalSettings.locations' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      locationServices.update(this.id, this.location).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'generalSettings.locations' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      locationServices.findOne(this.id).then(response => {
        this.location.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }

    if (!this.disableStatus) {
      citiesServices.findAll().then((res) => {
        this.cities = res.data.data
      })
    }
  }
}
</script>
